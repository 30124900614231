import React, { useEffect, useState } from "react";
import SingleImageUploader from "../../../components/SingleImageUploader";
import { Button } from "../../../components/buttons";
import {
  FieldLabel,
  SelectField,
  TextAreaField,
  TextField,
} from "../../../components/inputs";
import { K_COLLECTION_POSITIONS } from "../../../constantData/K_COLLECTION_POSITIONS";

export default function CollectionForm({ type, initialValues, onSave }) {
  const [formState, setFormState] = useState(initialValues);

  const getUploadedImages = (file) => {
    if (file.uri) {
      setFormState({ ...formState, ["image"]: file.uri });
    }
  };

  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  // // // // // //
  useEffect(() => {
    if (type === "edit") {
      let find_position = K_COLLECTION_POSITIONS.find(
        (x) => x.value === initialValues.position
      );
      setFormState({
        position: find_position ? find_position : {},
        image: initialValues.image,
        name: initialValues.name,
        description: initialValues.description,
      });
    } else {
      setFormState(initialValues);
    }
  }, [type, initialValues]);

  // // // // // //

  const handleSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      let submittedValues = {
        name: formState.name,
        position: formState.position ? formState.position.value : "",
        image: formState.image,
        description: formState.description,
      };
      onSave(submittedValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.image) {
      errors.image = "Collection image is required.";
    }
    if (!values.name) {
      errors.name = "Collection name is required.";
    }

    return errors;
  };

  return (
    <div className="w-full">
      <div className="w-1/3">
        <TextField
          type="text"
          label="Collection Name"
          name="name"
          value={formState.name}
          onChange={(name) =>
            handleChange({
              target: { value: name, name: "name" },
            })
          }
          error={formErrors.name}
        />
        <SelectField
          label="Position"
          placeHolder="Select position"
          selectOptions={K_COLLECTION_POSITIONS}
          value={formState.position}
          onChange={(position) =>
            handleChange({
              target: { value: position, name: "position" },
            })
          }
          error={formErrors.position}
        />

        <div className="w-full">
          <FieldLabel label="Collection Image" />
          <div className="w-full">
            <div className="w-full">
              <SingleImageUploader
                onUpload={getUploadedImages}
                imageError={formErrors.image}
                uri={formState.image}
                folderName="collections"
                compress={false}
              />
            </div>
          </div>
        </div>
        <TextAreaField
          type="text"
          label="Description"
          name="description"
          value={formState.description}
          rows={6}
          placeholder="Collection description..."
          onChange={(description) =>
            handleChange({
              target: { value: description, name: "description" },
            })
          }
        />

        <div className="mt-8 w-full bg-red-200">
          <Button
            name={type === "new" ? "Save" : "Update"}
            action={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
