"use client";
import React, { useState } from "react";
import { AiOutlineMessage } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkDialogExistRequest } from "../../api/messageRequests";
import {
  BlackOutlineButton,
  Button
} from "../../components/buttons";
import { OrderActionDialog, SendMessageDialog, OrderActionWithFilesDialog, ShipOrderDialog, MakeOfferDialog, AdditionaServicesDialog } from "../../components/dialogs";

//import ShipOrderModal from "./ShipOrderModal";

function OrderActionButton({
  orderType,
  actionName,
  actionButtonName,
  orderId,
  userType,
  successActionDone,
  modalTitle,
  modalText,
  successActionText,
  modalButtonText,
}) {
  const [modal, setModal] = useState(false);
  return (
    <div>
      {modal && (
        <OrderActionDialog
          orderType={orderType}
          modalOpen={modal}
          actionName={actionName}
          modalTitle={modalTitle}
          modalText={modalText}
          successActionText={successActionText}
          modalButtonText={modalButtonText}
          orderId={orderId}
          onClose={() => setModal(false)}
          userType={userType}
          successActionDone={successActionDone}
        />
      )}
      {actionName === "CANCEL_ORDER" ? (
        <button
          className={`underline hover:text-brandYellow`}
          onClick={() => setModal(true)}
        >
          {actionButtonName}
        </button>
      ) : (
        <Button name={actionButtonName} action={() => setModal(true)} />
      )}
    </div>
  );
}

function ActionWithFileButton({
  orderType,
  actionName,
  actionButtonName,
  modalTitle,
  modalText,
  successActionText,
  orderId,
  userType,
  successActionDone,
  modalButtonText
}) {
  const [modal, setModal] = useState(false);
  return (
    <div>
      {modal && (
        <OrderActionWithFilesDialog
        orderType={orderType}
        modalTitle={modalTitle}
        modalText={modalText}
        successActionText={successActionText}
          modalOpen={modal}
          orderId={orderId}
          onClose={() => setModal(false)}
          userType={userType}
          actionName={actionName}
          successActionDone={successActionDone}
          modalButtonText={modalButtonText}
        />
      )}
      <Button name={actionButtonName} action={() => setModal(true)} />
    </div>
  );
}

function ContactButton({recipientId, productId, refType, buttonName }) {
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [modal, setModal] = useState(false);

  const openMessageModal = async () => {
    try {
      const responseData = await checkDialogExistRequest(
        productId,
        recipientId,
        config
      );

      if (responseData.data.success) {
        if (responseData.data.data.existId) {
          navigate(`/messages/details/${responseData.data.data.existId}`);
        } else {
          setModal(true);
        }
      } else {
        throw responseData.data.message;
      }
    } catch (error) {}
  };

  return (
    <div className="w-full">
      {modal && (
        <div className="absolute top-0 left-0">
          <SendMessageDialog
            modalOpen={modal}
            productId={productId}
            recipientId={recipientId}
            refType={refType}
            onClose={() => setModal(false)}
          />
        </div>
      )}
      <BlackOutlineButton
        name={buttonName}
        action={openMessageModal}
        icon={<AiOutlineMessage />}
      />
    </div>
  );
}

function ShipOrderButton({
  orderType,
  actionName,
  actionButtonName,
  orderId,
  userType,
  successActionDone,
  modalTitle,
  modalText,
  successActionText,
  modalButtonText,
}) {
  const [modal, setModal] = useState(false);
  return (
    <div>
      {modal && (
        <ShipOrderDialog
        orderType={orderType}
          modalOpen={modal}
          modalTitle={modalTitle}
          modalText={modalText}
          successActionText={successActionText}
          modalButtonText={modalButtonText}
          orderId={orderId}
          onClose={() => setModal(false)}
          userType={userType}
          actionName={actionName}
          successActionDone={successActionDone}
        />
      )}
      <Button name={actionButtonName} action={() => setModal(true)} />
    </div>
  );
}

function MakeAnOfferButton({
  orderType,
  actionName,
  actionButtonName,
  orderId,
  userType,
  successActionDone,
  modalTitle,
  modalText,
  successActionText,
  modalButtonText,
  needOfferPrice,
  needCommision,
  needService,
  oriOfferPrice,
  oriCommision,
  oriServiceFee
}) {
  const [modal, setModal] = useState(false);
  return (
    <div>
      {modal && (
        <MakeOfferDialog
          orderType={orderType}
          modalOpen={modal}
          modalTitle={modalTitle}
          modalText={modalText}
          successActionText={successActionText}
          modalButtonText={modalButtonText}
          orderId={orderId}
          userType={userType}
          onClose={() => setModal(false)}
          actionName={actionName}
          successActionDone={successActionDone}
          needService={needService}
          needOfferPrice={needOfferPrice}
          needCommision={needCommision}
          oriOfferPrice={oriOfferPrice}
          oriCommision={oriCommision}
          oriServiceFee={oriServiceFee}
        />
      )}
      <Button name={actionButtonName} action={() => setModal(true)} />
    </div>
  );
}

function AdditionalServiceButton({
  orderType,
  actionName,
  actionButtonName,
  orderId,
  userType,
  successActionDone,
  modalTitle,
  modalText,
  successActionText,
  modalButtonText,
  oriServiceFee,
  addServices
}) {
  const [modal, setModal] = useState(false);
  return (
    <div>
      {modal && (
        <AdditionaServicesDialog
          orderType={orderType}
          modalOpen={modal}
          modalTitle={modalTitle}
          modalText={modalText}
          successActionText={successActionText}
          modalButtonText={modalButtonText}
          orderId={orderId}
          userType={userType}
          onClose={() => setModal(false)}
          actionName={actionName}
          successActionDone={successActionDone}
          oriServiceFee={oriServiceFee}
          addServices={addServices}
        />
      )}
      <Button name={actionButtonName} action={() => setModal(true)} />
    </div>
  );
}

export { ContactButton, OrderActionButton, ActionWithFileButton, ShipOrderButton, MakeAnOfferButton, AdditionalServiceButton};

