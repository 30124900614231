import React, { useState } from "react";
import { IoDocumentTextOutline, IoImageOutline, IoAnalytics } from "react-icons/io5";
import { AiOutlineMessage, AiOutlineUser, AiOutlineInbox } from "react-icons/ai";
import { MdOutlineWatch, MdOutlineWatchLater } from "react-icons/md";

import menuStyle from "../../style/menuStyle.module.css";
import CollapsedMenu from "./CollapsedMenu";
import SideMenuItem from "./SideMenuItem";

import {
  BsBoxArrowInLeft,
  BsBoxArrowInRight
} from "react-icons/bs";
// import {Modal} from "../../components/viewComponents";

function SideMenu() {
  // const { userDetails, success } = useSelector((state) => state.userDetail);

  const [isCollapsed, setCollapsed] = useState(true);

  const menuItems = [
    { menuName: "dashboard", path: "/", icon: <IoAnalytics /> },
    {
      menuName: "Orders",
      path: "",
      icon: <AiOutlineInbox />,
      children: [
        { menuName: "Order List", path: "/orders" },
        { menuName: "Consignment", path: "/consignment/quote-list" },
        { menuName: "Service", path: "/service/quote-list" },
      ],
    },
    {
      menuName: "Message",
      path: "",
      icon: <AiOutlineMessage />,
      children: [
        { menuName: "Messages", path: "/messages" },
     

      ],
    },
    {
      menuName: "Products",
      path: "",
      icon: <MdOutlineWatchLater />,
      children: [
        { menuName: "Product List", path: "/products" },
        { menuName: "New Product", path: "/products/new" },
     

      ],
    },
    {
      menuName: "Users",
      path: "",
      icon: <AiOutlineUser />,
      children: [
        { menuName: "Dealer List", path: "/users/dealer" },
        { menuName: "Private Seller List", path: "/users/private-seller" },
        { menuName: "General User List", path: "/users" },
        { menuName: "Add New User", path: "/users/new" },
     

      ],
    },
    {
      menuName: "Watches",
      path: "",
      icon: <MdOutlineWatch />,
      children: [
        { menuName: "Watch List", path: "/watches" },
        { menuName: "Add New Watch", path: "/watch/new" },
        { menuName: "Watch Brands", path: "/watch/brands" },
        { menuName: "Watch Models", path: "/watch/models" },

      ],
    },

    {
      menuName: "Blog",
      path: "",
      icon: <IoImageOutline />,
      children: [
        { menuName: "Add New Blog", path: "/blog/new" },
        { menuName: "Blog List", path: "/blogs" },
      ],
    },
    {
      menuName: "WebSite Content",
      path: "",
      icon: <IoDocumentTextOutline />,
      children: [
        { menuName: "Collections", path: "/web-content/collections" },
        { menuName: "Ads", path: "/web-content/ads" },
        { menuName: "FAQ", path: "/web-content/faq" },
        { menuName: "Website Images", path: "/web-content/images" },
        { menuName: "Website Agreements", path: "/web-content/agreements" },
        { menuName: "Career", path: "/web-content/career" },
        { menuName: "Company Details", path: "/web-content/company-details" },

      ],
    },
  ];

  return isCollapsed ? (
    <div className={menuStyle.sidemenuContainer}>
      <div className={menuStyle.sidemenu}>
        {menuItems.map((item, index) => (
          <SideMenuItem key={index} item={item} />
        ))}
      </div>
      <div
        className={menuStyle.collapsedCon}
        onClick={() => setCollapsed(false)}
      >
        <BsBoxArrowInLeft />
        <p>Collapse Side Menu</p>
      </div>
    </div>
  ) : (
    <div className={menuStyle.sidemenuContainerColla}>
      <div className={menuStyle.sidemenu}>
        {menuItems.map((item,index) => (
          <CollapsedMenu key={index} item={item} />
        ))}
      </div>
      <div
        className={menuStyle.collapsedCon}
        onClick={() => setCollapsed(true)}
      >
        <BsBoxArrowInRight />
      </div>
    </div>
  );
}

export default SideMenu;
