import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Button, LoadingButton } from "../../components/buttons";
import {
  FieldLabel,
  RatioField,
  ReadOnlyField,
  SelectField,
  TextAreaField,
  TextField,
} from "../../components/inputs";
import { ErrorMessage, H2 } from "../../components/smallComponents";
import ImagesUploader from "./ImagesUploader";

export default function PlatformProductDetailsForm({
  type,
  onSave,
  submitLoading,
  submitError,
  initialDetails,
  pricEditable,
}) {
  const dispatch = useDispatch();

  const { watchOptions } = useSelector((state) => state.watchConstantOptions);

  const [formState, setFormState] = useState(initialDetails);

  const [formErrors, setFormErrors] = useState({});
  const [showAdditionalInfo, setShowAdditionalInfo] = useState("");
  const [formImagesError, setFormImagesError] = useState("");

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const handleShowButton = (e, value) => {
    e.preventDefault();
    if (value === showAdditionalInfo) {
      setShowAdditionalInfo("");
    } else {
      setShowAdditionalInfo(value);
    }
  };
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    const type = e.type;

    if (type === "FLOAT") {
      const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
      if (!validated) {
        return;
      }
    }
    if (type === "INT") {
      const validated = value.match(/^(?:\d+)?$/g);
      if (!validated) {
        return;
      }
    }

    setFormState({ ...formState, [name]: value });
  };

  const handleRatioChange = (e) => {
    const { id, value } = e.target;
    const type = e.type;

    let copy = { ...formState };
    let typeArray = copy[type]; // an array

    // check if id exist

    if (typeArray.includes(id)) {
      let existIndex = typeArray.indexOf(id);
      if (!value) {
        typeArray.splice(existIndex, 1);
      }
    } else {
      if (value) {
        typeArray.push(id);
      }
    }
    setFormState(copy);
  };

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const getWatchImages = (files) => {
    setFormState({
      ...formState,
      ["imageUris"]: files,
    });
  };

  ///////////////////////////////////////////////////////////////

  const handleSaveListing = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      onSave(formState);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.modelName) {
      errors.modelName = "Watch model is required.";
    }
    if (!values.watchTypeId) {
      errors.watchType = "Watch type is required.";
    }

    if (!values.genderId) {
      errors.gender = "Watch gender is required.";
    }

    if (!values.movementId) {
      errors.movement = "Watch movement is required.";
    }

    if (!values.conditionId) {
      errors.condition = "Watch condition is required.";
    }
    if (!values.scopeId) {
      errors.scope = "Watch scope of delivery is required.";
    }
    if (!values.price) {
      errors.price = "Watch price is required.";
    }
    if (!values.imageUris.length) {
      errors.imageUris = "Please add at least one watch photo.";
    }

    return errors;
  };

  //////////////////////////////////////////////////////////////

  const AdditionalInfoTitle = ({ title, num }) => {
    return (
      <div className="flex items-center">
        <p>{title}</p>
        <span className="text-brandYellow text-sm ml-4">{num} Suggestions</span>
      </div>
    );
  };

  return (
    <div className="w-full pb-20 pt-4 mt-4">
      <div className="w-full flex">
        <div className="w-full lg:w-3/5">
          <div className="w-full">
            <H2 title="Watch Images" />
            <ImagesUploader
              initialImages={formState.imageUris}
              numLimit={20}
              onWatchUpload={getWatchImages}
              tipsText={
                "Upload at least 3 photos of the watch."
              }
            />

            {formErrors.imageUris ? (
               <p className="mt-4 text-red-500">{formErrors.imageUris}</p>
            ) : (
              <></>
            )}
          </div>

          <div className="w-full">
            <div className="w-full">
              <div className="mt-6">
                <H2 title="Watch Basic Information" subText="(Required)" />
              </div>

              <div className="w-full flex flex-col lg:flex-row">
                <div className="w-full lg:w-1/2 lg:mr-8">
                  <ReadOnlyField
                    label="Brand*"
                    readOnlyName={formState.brandName}
                  />
                  <SelectField
                    label="Type of Watch*"
                    placeHolder="Select type"
                    selectOptions={watchOptions.watchTypes}
                    value={formState.watchTypeId}
                    extractKey="id"
                    onChange={(watchTypeId) =>
                      handleChange({
                        type: "ID",
                        target: { value: watchTypeId, name: "watchTypeId" },
                      })
                    }
                    error={formErrors.watchType}
                  />
                  <SelectField
                    label="Gender*"
                    placeHolder="Select gender"
                    selectOptions={watchOptions.genders}
                    value={formState.genderId}
                    extractKey="id"
                    onChange={(genderId) =>
                      handleChange({
                        type: "ID",
                        target: { value: genderId, name: "genderId" },
                      })
                    }
                    error={formErrors.gender}
                  />
                  <div className="flex w-full">
                    <div className="w-1/2">
                      <TextField
                        type="text"
                        label="Case Diameter"
                        name="caseDiameterWidth"
                        autofocus={true}
                        value={formState.caseDiameterWidth}
                        onChange={(caseDiameterWidth) =>
                          handleChange({
                            type: "FLOAT",
                            target: {
                              value: caseDiameterWidth,
                              name: "caseDiameterWidth",
                            },
                          })
                        }
                        unit="mm"
                      />
                    </div>

                    <span className="mx-4 mt-16">X</span>
                    <div className="mt-6 w-1/2">
                      <TextField
                        type="text"
                        label=""
                        name="caseDiameterHeight"
                        autofocus={true}
                        value={formState.caseDiameterHeight}
                        onChange={(caseDiameterHeight) =>
                          handleChange({
                            type: "FLOAT",
                            target: {
                              value: caseDiameterHeight,
                              name: "caseDiameterHeight",
                            },
                          })
                        }
                        unit="mm"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <TextField
                    type="text"
                    label="Model*"
                    name="modelName"
                    value={formState.modelName}
                    onChange={(modelName) =>
                      handleChange({
                        type: "TEXT",
                        target: {
                          value: modelName,
                          name: "modelName",
                        },
                      })
                    }
                    error={formErrors.modelName}
                  />

                  <TextField
                    type="text"
                    label="Reference Number*"
                    name="referenceNumber"
                    autofocus={true}
                    value={formState.referenceNumber}
                    onChange={(referenceNumber) =>
                      handleChange({
                        type: "TEXT",
                        target: {
                          value: referenceNumber,
                          name: "referenceNumber",
                        },
                      })
                    }
                    error={formErrors.referenceNumber}
                  />

                  <SelectField
                    label="Movement*"
                    placeHolder=""
                    selectOptions={watchOptions.movemnets}
                    value={formState.movementId}
                    extractKey={"id"}
                    onChange={(movementId) =>
                      handleChange({
                        type: "ID",
                        target: { value: movementId, name: "movementId" },
                      })
                    }
                    error={formErrors.movement}
                  />

                  <TextField
                    type="text"
                    label="Year of Product"
                    name="referenceNumber"
                    autofocus={true}
                    value={formState.year}
                    onChange={(year) =>
                      handleChange({
                        type: "INT",
                        target: { value: year, name: "year" },
                      })
                    }
                    error={formErrors.year}
                  />
                </div>
              </div>
              <div className="mt-6">
                <H2 title="Price" subText="(Required)" />
              </div>

              <div className="w-full">
                <TextField
                  type="text"
                  label="Price*"
                  name="price"
                  autofocus={true}
                  readOnly={pricEditable ? false : true}
                  value={formState.price}
                  onChange={(price) =>
                    handleChange({
                      type: "FLOAT",
                      target: { value: price, name: "price" },
                    })
                  }
                  dollarIcon={true}
                  error={formErrors.price}
                />

                <SelectField
                  label="Condition*"
                  placeHolder="Select type"
                  selectOptions={watchOptions.watchConditions}
                  value={formState.conditionId}
                  extractKey="id"
                  onChange={(conditionId) =>
                    handleChange({
                      type: "ID",
                      target: { value: conditionId, name: "conditionId" },
                    })
                  }
                  error={formErrors.condition}
                />
                <SelectField
                  label="Scope of delivery*"
                  placeHolder=""
                  selectOptions={watchOptions.watchScopes}
                  value={formState.scopeId}
                  extractKey="id"
                  onChange={(scopeId) =>
                    handleChange({
                      type: "ID",
                      target: { value: scopeId, name: "scopeId" },
                    })
                  }
                  error={formErrors.scope}
                />
                <TextAreaField
                  type="text"
                  label="Description"
                  name="description"
                  value={formState.description}
                  rows={6}
                  placeholder="For example, where did you buy the watch? Does it still have a warranty ? Has it been repaired? Is the watch damaged?"
                  onChange={(description) =>
                    handleChange({
                      type: "TEXT",
                      target: { value: description, name: "description" },
                    })
                  }
                />
              </div>

              <div>
                <div className="mt-6">
                  <H2 title="Additional Info" subText="Optional" />
                </div>

                <button
                  className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                  onClick={(e) => handleShowButton(e, "caliber")}
                >
                  <AdditionalInfoTitle
                    title="Caliber"
                    num={formState.caliberCount}
                  />
                  <MdKeyboardArrowDown
                    className={`transform transition-transform duration-300 ease-in-out origin-center ${
                      showAdditionalInfo === "caliber"
                        ? "rotate-180"
                        : "rotate-0"
                    }`}
                  />
                </button>
                {showAdditionalInfo === "caliber" ? (
                  <div>
                    <div className="w-full flex flex-col lg:flex-row">
                      <div className="w-full lg:w-1/2 lg:mr-8">
                        <TextField
                          type="text"
                          label="Caliber/movement"
                          name="caliMovement"
                          autofocus={true}
                          value={formState.caliMovement}
                          onChange={(caliMovement) =>
                            handleChange({
                              type: "TEXT",
                              target: {
                                value: caliMovement,
                                name: "caliMovement",
                              },
                            })
                          }
                        />
                        <TextField
                          type="text"
                          label="Base caliber"
                          name="baseCaliber"
                          autofocus={true}
                          value={formState.baseCaliber}
                          onChange={(baseCaliber) =>
                            handleChange({
                              type: "TEXT",
                              target: {
                                value: baseCaliber,
                                name: "baseCaliber",
                              },
                            })
                          }
                        />
                        <TextField
                          type="text"
                          label="Frequency"
                          name="frequency"
                          autofocus={true}
                          value={formState.frequency}
                          onChange={(frequency) =>
                            handleChange({
                              type: "INT",
                              target: {
                                value: frequency,
                                name: "frequency",
                              },
                            })
                          }
                          unit="A/h"
                        />
                      </div>
                      <div className="w-full lg:w-1/2">
                        <TextField
                          type="text"
                          label="Power Reserve"
                          name="powerReserve"
                          autofocus={true}
                          value={formState.powerReserve}
                          onChange={(powerReserve) =>
                            handleChange({
                              type: "FLOAT",
                              target: {
                                value: powerReserve,
                                name: "powerReserve",
                              },
                            })
                          }
                          unit="h"
                        />
                        <TextField
                          type="text"
                          label="Number of Jewels"
                          name="jewelNumbers"
                          autofocus={true}
                          value={formState.jewelNumbers}
                          onChange={(jewelNumbers) =>
                            handleChange({
                              type: "INT",
                              target: {
                                value: jewelNumbers,
                                name: "jewelNumbers",
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <FieldLabel label="Other Details" />
                    <ul className="w-full flex flex-wrap">
                      {watchOptions.caliberOtherDetails.map((item, index) => (
                        <li key={index} className="w-1/2">
                          <RatioField
                            name={item.name}
                            label={item.name}
                            isOn={
                              formState["otherAttributeIds"].length
                                ? formState["otherAttributeIds"].includes(
                                    item.id
                                  )
                                : false
                            }
                            onChange={(details) =>
                              handleRatioChange({
                                type: "otherAttributeIds",
                                target: { id: item.id, value: details },
                              })
                            }
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}

                <button
                  className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                  onClick={(e) => handleShowButton(e, "case")}
                >
                  <AdditionalInfoTitle
                    title="Case"
                    num={formState.caliberCount}
                  />
                  <MdKeyboardArrowDown
                    className={`transform transition-transform duration-300 ease-in-out origin-center ${
                      showAdditionalInfo === "case" ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </button>

                {showAdditionalInfo === "case" ? (
                  <div className="w-full">
                    <div className="w-full flex flex-col lg:flex-row">
                      <div className="w-full lg:w-1/2 mr-8">
                        <SelectField
                          label="Case Material"
                          placeHolder=""
                          selectOptions={watchOptions.caseMaterials}
                          value={formState.caseMaterialId}
                          extractKey={"id"}
                          onChange={(caseMaterialId) =>
                            handleChange({
                              type: "ID",
                              target: {
                                value: caseMaterialId,
                                name: "caseMaterialId",
                              },
                            })
                          }
                        />

                        <SelectField
                          label="Bezel Material"
                          placeHolder=""
                          selectOptions={watchOptions.caseMaterials}
                          value={formState.bezelMaterialId}
                          extractKey={"id"}
                          onChange={(bezelMaterialId) =>
                            handleChange({
                              type: "ID",
                              target: {
                                value: bezelMaterialId,
                                name: "bezelMaterialId",
                              },
                            })
                          }
                        />
                        <TextField
                          type="text"
                          label="Thickness"
                          name="thickness"
                          autofocus={true}
                          value={formState.thickness}
                          onChange={(thickness) =>
                            handleChange({
                              type: "FLOAT",
                              target: {
                                value: thickness,
                                name: "thickness",
                              },
                            })
                          }
                          unit="mm"
                        />
                      </div>
                      <div className="w-full lg:w-1/2">
                        <SelectField
                          label="Crystal"
                          placeHolder=""
                          selectOptions={watchOptions.caseCrystals}
                          value={formState.glassId}
                          extractKey={"id"}
                          onChange={(glassId) =>
                            handleChange({
                              type: "ID",
                              target: {
                                value: glassId,
                                name: "glassId",
                              },
                            })
                          }
                        />
                        <SelectField
                          label="Watch Resistance"
                          placeHolder=""
                          selectOptions={watchOptions.watchResistances}
                          value={formState.waterResistanceId}
                          extractKey={"id"}
                          onChange={(waterResistanceId) =>
                            handleChange({
                              type: "ID",
                              target: {
                                value: waterResistanceId,
                                name: "waterResistanceId",
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <FieldLabel label="Other Details" />
                    <ul className="w-full flex flex-wrap">
                      {watchOptions.caseOtherDetails.map((item, index) => (
                        <li key={index} className="w-1/2">
                          <RatioField
                            name={item.name}
                            label={item.name}
                            isOn={
                              formState["otherAttributeIds"]
                                ? formState["otherAttributeIds"].includes(
                                    item.id
                                  )
                                : false
                            }
                            onChange={(details) =>
                              handleRatioChange({
                                type: "otherAttributeIds",
                                target: { id: item.id, value: details },
                              })
                            }
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}

                <button
                  className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                  onClick={(e) => handleShowButton(e, "dial")}
                >
                  <AdditionalInfoTitle
                    title="Dial and hands"
                    num={formState.dialCount}
                  />
                  <MdKeyboardArrowDown
                    className={`transform transition-transform duration-300 ease-in-out origin-center ${
                      showAdditionalInfo === "dial" ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </button>

                {showAdditionalInfo === "dial" ? (
                  <div className="w-full">
                    <div className="w-full flex flex-col lg:flex-row">
                      <div className="w-full lg:w-1/2 lg:mr-8">
                        <SelectField
                          label="Dial Color"
                          placeHolder=""
                          selectOptions={watchOptions.dialColors}
                          value={formState.dialColorId}
                          extractKey={"id"}
                          onChange={(dialColorId) =>
                            handleChange({
                              type: "ID",
                              target: {
                                value: dialColorId,
                                name: "dialColorId",
                              },
                            })
                          }
                        />
                      </div>
                      <div className="w-full lg:w-1/2">
                        <SelectField
                          label="Dial Numerals"
                          placeHolder=""
                          selectOptions={watchOptions.dialNumerals}
                          value={formState.dialNumeralsId}
                          extractKey={"id"}
                          onChange={(dialNumeralsId) =>
                            handleChange({
                              type: "ID",
                              target: {
                                value: dialNumeralsId,
                                name: "dialNumeralsId",
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <FieldLabel label="Dial Features" />
                    <ul className="w-full flex flex-wrap">
                      {watchOptions.dialFeatures.map((item, index) => (
                        <li key={index} className="w-1/2">
                          <RatioField
                            name={item.name}
                            label={item.name}
                            isOn={
                              formState["otherAttributeIds"]
                                ? formState["otherAttributeIds"].includes(
                                    item.id
                                  )
                                : false
                            }
                            onChange={(details) =>
                              handleRatioChange({
                                type: "otherAttributeIds",
                                target: { id: item.id, value: details },
                              })
                            }
                          />
                        </li>
                      ))}
                    </ul>
                    <FieldLabel label="Hand Details" />
                    <ul className="w-full flex flex-wrap">
                      {watchOptions.handDetails.map((item, index) => (
                        <li key={index} className="w-1/2">
                          <RatioField
                            name={item.name}
                            label={item.name}
                            isOn={
                              formState["otherAttributeIds"]
                                ? formState["otherAttributeIds"].includes(
                                    item.id
                                  )
                                : false
                            }
                            onChange={(details) =>
                              handleRatioChange({
                                type: "otherAttributeIds",
                                target: { id: item.id, value: details },
                              })
                            }
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}

                <button
                  className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                  onClick={(e) => handleShowButton(e, "bracelet")}
                >
                  <AdditionalInfoTitle
                    title="Bracelet/Strap"
                    num={formState.braceletCount}
                  />
                  <MdKeyboardArrowDown
                    className={`transform transition-transform duration-300 ease-in-out origin-center ${
                      showAdditionalInfo === "bracelet"
                        ? "rotate-180"
                        : "rotate-0"
                    }`}
                  />
                </button>

                {showAdditionalInfo === "bracelet" ? (
                  <div className="w-full flex flex-col lg:flex-row">
                    <div className="w-full lg:w-1/2 lg:mr-8">
                      <SelectField
                        label="Bracelet Material"
                        placeHolder=""
                        selectOptions={watchOptions.breceletMaterials}
                        value={formState.braceletMaterialId}
                        extractKey={"id"}
                        onChange={(braceletMaterialId) =>
                          handleChange({
                            type: "ID",
                            target: {
                              value: braceletMaterialId,
                              name: "braceletMaterialId",
                            },
                          })
                        }
                      />

                      <SelectField
                        label="Bracelet Color"
                        placeHolder=""
                        selectOptions={watchOptions.breceletColors}
                        value={formState.braceletColorId}
                        extractKey={"id"}
                        onChange={(braceletColorId) =>
                          handleChange({
                            type: "ID",
                            target: {
                              value: braceletColorId,
                              name: "braceletColorId",
                            },
                          })
                        }
                      />
                    </div>
                    <div className="w-full lg:w-1/2">
                      <SelectField
                        label="Type of clasp"
                        placeHolder=""
                        selectOptions={watchOptions.watchClasps}
                        value={formState.claspId}
                        extractKey={"id"}
                        onChange={(claspId) =>
                          handleChange({
                            type: "ID",
                            target: { value: claspId, name: "claspId" },
                          })
                        }
                      />
                      <SelectField
                        label="Clasp Material"
                        placeHolder=""
                        selectOptions={watchOptions.breceletMaterials}
                        value={formState.claspMaterialId}
                        extractKey={"id"}
                        onChange={(claspMaterialId) =>
                          handleChange({
                            type: "ID",
                            target: {
                              value: claspMaterialId,
                              name: "claspMaterialId",
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <button
                  className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                  onClick={(e) => handleShowButton(e, "function")}
                >
                  <AdditionalInfoTitle
                    title="Functions"
                    num={formState.functionCount}
                  />
                  <MdKeyboardArrowDown
                    className={`transform transition-transform duration-300 ease-in-out origin-center ${
                      showAdditionalInfo === "function"
                        ? "rotate-180"
                        : "rotate-0"
                    }`}
                  />
                </button>

                {showAdditionalInfo === "function" ? (
                  <ul className="w-full flex flex-wrap">
                    {watchOptions.watchFunctions.map((item, index) => (
                      <li key={index} className="w-1/2">
                        <RatioField
                          name={item.name}
                          label={item.name}
                          isOn={
                            formState["watchFunctionsIds"].length > 0
                              ? formState["watchFunctionsIds"].includes(item.id)
                              : false
                          }
                          onChange={(details) =>
                            handleRatioChange({
                              type: "watchFunctionsIds",
                              target: { id: item.id, value: details },
                            })
                          }
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex items-start">
        <div className="w-full lg:w-3/5">
          {submitError && (
            <div className="mt-8 w-full">
              <ErrorMessage mess={submitError} />
            </div>
          )}

          <div className="mt-8 w-full flex justify-between">
            {submitLoading ? (
              <LoadingButton name="Submitting..." />
            ) : (
              <Button name={"Submit Listing"} action={handleSaveListing} />
            )}
          </div>
        </div>
      </div>

      {/* step 4 end */}
    </div>
  );
}
