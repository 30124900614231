import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { orderActionRequest } from "../../api/orderRequests";
import { addNewProductByConsignmentRequest, getWatchDetailsRequest } from "../../api/productsRequests";
import ScreenTitle from "../../components/ScreenTitle";
import { SimpleDialog } from "../../components/dialogs";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import {
  watchConstantOptionsRequest
} from "../../redux/actions/watchActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import PlatformProductDetailsForm from "./PlatformProductDetailsForm";
import SearchWatch from "./SearchWatch";

export default function NewProductByConsignment({}) {
  useDocumentTitle("Add New Product");
  const { id: consignmentId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const consignmentDetails = location.state;

  console.log(consignmentDetails);

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const { loading, watchOptions, success, error } = useSelector(
    (state) => state.watchConstantOptions
  );


  const [formState, setFormState] = useState({});

  const [dialog, setDialog] = useState(false);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [consignStatus, setConsignStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [watchId, setWatchId] = useState("");
  const [showSearchField, setShowSearchField] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const [productId, setProductId] = useState("");

  useEffect(() => {
    dispatch(watchConstantOptionsRequest());
  }, []);

  useEffect(() => {
    const getWatchInfo = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getWatchDetailsRequest(
          watchId,
          config,
          dispatch
        );
        if (responseData.data.success) {
          let watch = responseData.data.data;
          watch.year = consignmentDetails.year;
          watch.conditionId = "";
          watch.scopeId = "";
          watch.serialNumber = consignmentDetails.serialNumber;
          watch.price = consignmentDetails.listingPrice;
          watch.description = "";
          watch.imageUris = [];
          watch.ownerProofOne = "";
          watch.ownerProofTwo = "";

          setFormState(watch);
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          setShowForm(true);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    if (watchId) {
      getWatchInfo();
    }
  }, [watchId]);

  const submitListing = async (values) => {
    let submittedValues = values;
    let contact_info = {
      firstName: "",
      lastName: "",
      phone: "",
      streetLine1: "",
      region: "",
      country: "",
      city: "",
      postalCode: "",
    };

    let uris = submittedValues.imageUris;
    let pureUris = [];
    uris.forEach((x) => {
      if (x.uri) {
        pureUris.push(x.uri);
      }
    });

    submittedValues.productType = "CONSIGNEDPRODUCT";
    submittedValues.watchImages = pureUris;
    submittedValues.ownerProofImage1 = "";
    submittedValues.ownerProofImage2 = "";
    submittedValues.contact = contact_info;
   
    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await addNewProductByConsignmentRequest(
        consignmentId,
        "",
        values,
        config,
      );
      if (responseData.data.success) {
        setProductId(responseData.data.data);
        proceedConsignmentAction();
        setDialog(true);
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const proceedConsignmentAction = async () => {
    try {
      setConsignStatus({ ...consignStatus, loading: true });
      const responseData = await orderActionRequest(
        "consignment",
        consignmentId,
        "platform",
        "PRODUCT_LISTED",
        "",
        config
      );

      if (responseData.data.success) {
        setConsignStatus({ ...consignStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setConsignStatus({ ...consignStatus, loading: false, error: error });
    }
  };


  const handleWatchSelect = (id) => {
    setWatchId(id);
    setShowSearchField(false);
    setShowForm(true);
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate(`/consignment/details/${consignmentId}`);
  }

  return (
    <div className={commonStyle.pageContainer}>
      
       <SimpleDialog
        title="Add New Product"
        successMessage="New product added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/products">
            Products
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/products/new/`}
            aria-current="page"
          >
            New Product
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Product (Consignment)" />

        {showSearchField && (
          <div className="mt-8 w-1/2">
            <p className="mb-2 text-base ">Select a watch to start</p>
            <SearchWatch onSelect={handleWatchSelect} keywordPreset={consignmentDetails.brandName + " " + consignmentDetails.modelName + " " + consignmentDetails.referenceNumber}/>
          </div>
        )}

        {consignStatus.error && (
          <div className="mt-8 w-full">
            <ErrorMessage mess={consignStatus.error } />
          </div>
        )}

        {success && showForm ? (
          <>
            {reqStatus.loading ? (
              <Loader mess="Requeting watch options..." />
            ) : reqStatus.success ? (
              <PlatformProductDetailsForm
                type={"new"}
                onSave={submitListing}
                submitLoading={saveStatus.loading}
                submitError={saveStatus.error}
                initialDetails={formState}
                pricEditable={false}
              />
            ) : reqStatus.error ? (
              <ErrorMessage mess={reqStatus.error} />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

      </div>
    </div>
  );
}
