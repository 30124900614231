import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { convtPrice } from "../../api/functions";
import { removeProductRequest } from "../../api/userRequests";
import ScreenTitle from "../../components/ScreenTitle";
import {
  BlackOutlineButton,
  Button,
  LinkButton,
  LoadingButton,
} from "../../components/buttons";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import { productDetailsRequest } from "../../redux/actions/userActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { ContactButton } from "../order/orderActionButtons";
import ProductImageGallery from "./ProductImageGallery";
import { deleteProductListing } from "../../api/productsRequests";
import { SimpleDialog } from "../../components/dialogs";

export default function ProductDetails() {
  const { id } = useParams();
  useDocumentTitle("Product Details");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, details, success, error } = useSelector(
    (state) => state.productDetails
  );

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    dispatch(productDetailsRequest(id));
  }, [dispatch, reqStatus.success]);

  const removeProduct = async () => {
    try {
      setReqStatus({ ...reqStatus, loading: true, success: false, error: "" });

      const responseData = await removeProductRequest(id, config);

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const deleteProduct = async () => {
    try {
      setDeleteStatus({
        ...deleteStatus,
        loading: true,
        success: false,
        error: "",
      });
      const responseData = await deleteProductListing(id, config);

      if (responseData.data.success) {
        setDeleteDialog(true);
        setDeleteStatus({ ...deleteStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setDeleteStatus({ ...deleteStatus, loading: false, error: error });
    }
  };

  const DetailItem = ({ title, value }) => {
    return (
      <div className="w-full border-b border-borderGray flex mt-2 py-2">
        <span className="w-1/3 font-medium">{title}</span>
        <span className="w-2/3 text-darkGray pl-4">{value}</span>
      </div>
    );
  };

  const closeDialogWithSuccess = () => {
    setDeleteDialog(false);
    navigate(`/products`);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Delete Product"
        successMessage="Product deleted successfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        loading={deleteStatus.loading}
        loadingMessage="Processing request..."
        success={deleteStatus.success}
        error={deleteStatus.error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/products">
            Products
          </NavLink>
          <NavLink underline="hover" color="inherit">
            Details
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Product Details" />

        {loading ? (
          <Loader mess="Requesting details..." />
        ) : success ? (
          <div className="w-full my-0 lg:my-8 ">
            {/*basic info*/}
            <div className="flex flex-col lg:flex-row w-full">
              <div className="w-full lg:w-1/2">
                <ProductImageGallery images={details.imageUris} />
              </div>

              <div className="w-full lg:w-1/2 lg:ml-8 mt-8 lg:mt-0 px-4 lg:px-0">
                <p className="px-3 py-1 rounded bg-brandYellow w-fit mb-2">
                  {details.status}
                </p>
                <h1>{details.brandName + " " + details.modelName}</h1>
                <p>
                  {details.referenceNumber}{" "}
                  {details.caseDiameterWidth > 0
                    ? details.caseDiameterWidth + "mm"
                    : ""}{" "}
                  {details.caseMaterialName ? details.caseMaterialName : ""}{" "}
                  {details.dialColorName ? details.dialColorName : ""}{" "}
                </p>
                <div className="mt-2 flex flex-wrap">
                  <span className="px-2 py-1 rounded border border-solid border-brandYellow mr-2 mt-2">
                    {details.conditionName}
                  </span>
                  <span className="px-2 py-1 rounded border border-solid border-brandYellow mr-2 mt-2">
                    {details.scopeName}
                  </span>
                  {details.year > 0 && (
                    <span className="px-2 rounded border border-solid border-brandYellow mr-2 mt-2">
                      Year of production {details.year}
                    </span>
                  )}
                </div>
                <div className="mt-8">
                  <h2>{"$" + convtPrice(details.price)}</h2>
                </div>

                {details.type === "SELLERPRODUCT" && (
                  <div className="w-full my-4">
                    <div className="w-full py-4 flex justify-between">
                      <span className="font-semibold">
                        {details.sellerInfo.role}
                      </span>
                      <span className="font-semibold">
                        {details.sellerInfo.userName}
                      </span>
                    </div>{" "}
                    <ContactButton
                      recipientId={details.sellerId}
                      productId={id}
                      refType={"PRODUCT"}
                      buttonName={"Contact Seller"}
                    />
                  </div>
                )}
              </div>
            </div>
            {/*watch details info*/}
            <div className="w-full px-4 lg:px-0">
              <div className="w-full mt-12">
                <h2>Product Details</h2>

                <div className="w-full flex flex-col lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <div className="mt-6">
                      <h4>Basic Info</h4>

                      <DetailItem title="Brand" value={details.brandName} />
                      <DetailItem title="Model" value={details.modelName} />
                      <DetailItem
                        title="Reference Number"
                        value={details.referenceNumber}
                      />
                      <DetailItem title="Type" value={details.watchtypeName} />
                      <DetailItem
                        title="Year of production"
                        value={details.year ? details.year : ""}
                      />
                      <DetailItem title="Gender" value={details.genderName} />
                    </div>

                    <div className="mt-6">
                      <h4>Caliber/Movement</h4>

                      <DetailItem
                        title="Movement"
                        value={details.movementName}
                      />
                      {details.caliMovement && (
                        <DetailItem
                          title="Movement/Caliber"
                          value={details.caliMovement}
                        />
                      )}
                      {details.baseCaliber && (
                        <DetailItem
                          title="Base caliber"
                          value={
                            <DetailItem
                              title="Base caliber"
                              value={details.baseCaliber}
                            />
                          }
                        />
                      )}

                      {details.powerReserve && (
                        <DetailItem
                          title="Power Reserve"
                          value={details.powerReserve}
                        />
                      )}

                      {details.jewelNumbers > 0 && (
                        <DetailItem
                          title="Jewel numbers"
                          value={details.jewelNumbers}
                        />
                      )}
                      {details.frequency > 0 && (
                        <DetailItem
                          title="Frequency"
                          value={details.frequency + "A/h"}
                        />
                      )}
                    </div>

                    <div className="mt-6">
                      <h4>Case</h4>

                      {details.caseMaterialName && (
                        <DetailItem
                          title="Case material"
                          value={details.caseMaterialName}
                        />
                      )}

                      {details.caseDiameterWidth > 0 && (
                        <DetailItem
                          title="Case diameter"
                          value={details.caseDiameterWidth + "mm"}
                        />
                      )}

                      {details.waterResistanceName && (
                        <DetailItem
                          title="Water resistance"
                          value={details.waterResistanceName}
                        />
                      )}
                      {details.bezelMaterialName && (
                        <DetailItem
                          title="Bezel material"
                          value={details.bezelMaterialName}
                        />
                      )}
                      {details.glassName && (
                        <DetailItem title="Crystal" value={details.glassName} />
                      )}

                      {details.dialColorName && (
                        <DetailItem
                          title="Dial color"
                          value={details.dialColorName}
                        />
                      )}
                      {details.dialNumeralsName && (
                        <DetailItem
                          title="Dial numerals"
                          value={details.dialNumeralsName}
                        />
                      )}
                    </div>
                    <div className="mt-6">
                      <h4>Bracelet/Strap</h4>

                      {details.braceletMaterialName && (
                        <DetailItem
                          title="Bracelet material"
                          value={details.braceletMaterialName}
                        />
                      )}

                      {details.braceletColorName && (
                        <DetailItem
                          title="Bracelet color"
                          value={details.braceletColorName}
                        />
                      )}
                      {details.claspName && (
                        <DetailItem title="Clasp" value={details.claspName} />
                      )}
                      {details.claspMaterialName && (
                        <DetailItem
                          title="Clasp material"
                          value={details.claspMaterialName}
                        />
                      )}
                    </div>

                    <div className="mt-6">
                      <h4>Other Features</h4>

                      {details.otherAttributeNames.length ? (
                        details.otherAttributeNames.map((item, index) => (
                          <span key={index} className="mr-4 text-darkgray">
                            {item.name}
                          </span>
                        ))
                      ) : (
                        <></>
                      )}
                      {details.watchFunctionsNames.length ? (
                        details.watchFunctionsNames.map((item, index) => (
                          <span key={index} className="mr-4 text-darkgray">
                            {item}
                          </span>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 lg:ml-8 py-4">
                    <h4>Description</h4>
                    <pre className="mt-4">{details.description}</pre>
                  </div>
                </div>
              </div>
            </div>

            <h2 className="my-4">Admin Actions</h2>

            <div className="w-full my-4">
              {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
            </div>

            <div className="w-full my-4">
              {details.create_by_admin_id === userInfo.data.user_id ? (
                <LinkButton
                  name="Update Product"

                  path={
                    details.type === "SELLERPRODUCT"
                      ? `/products/seller/edit/${details.id}`
                      : details.type === "PLATFORMPRODUCT"
                      ? `/products/platform/edit/${details.id}`
                      : details.type === "CONSIGNEDPRODUCT"
                      ? `/products/consignment/edit/${details.id}`
                      : ``
                  }
                />
              ) : (
                <></>
              )}

            </div>

            <div className="w-full my-4">
              {details.status === "Active" ? (
                reqStatus.loading ? (
                  <LoadingButton name="Removing product..." />
                ) : (
                  <Button
                    name="Remove from Active Listing"
                    action={removeProduct}
                  />
                )
              ) : (
                <></>
              )}
            </div>

            {details.type !== "CONSIGNEDPRODUCT" && (
              <div className="w-full my-4">
                {details.create_by_admin_id === userInfo.data.user_id ? (
                  <BlackOutlineButton
                    name="Delete Product"
                    action={deleteProduct}
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
