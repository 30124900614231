import {
  EDIT_USER_FAIL,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  MESSAGE_DETAILS_FAIL,
  MESSAGE_DETAILS_REQUEST,
  MESSAGE_DETAILS_SUCCESS,
  MESSAGE_LIST_FAIL,
  MESSAGE_LIST_REQUEST,
  MESSAGE_LIST_SUCCESS,
  NEW_USER_FAIL,
  NEW_USER_REQUEST,
  NEW_USER_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_OPTIONS_LIST_REQUEST,
  USER_OPTIONS_LIST_SUCCESS,
  USER_OPTIONS_LIST_FAIL
} from "../constants/userConstants";
import axios from "axios";
import { authMiddleware } from "./authMiddleware";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const userLoginRequest = (values) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  try {
    dispatch({ type: LOGIN_REQUEST });

    axios
      .post(SERVER_URL + `/admin/user/login`, values, config)
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: LOGIN_SUCCESS, payload: response.data });
          localStorage.setItem("userInfo", JSON.stringify(response.data));
        } else {
          throw response.data.message;
        }
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

// when click logout delete local storage data "userinfo"
export const userlogout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: LOGOUT_REQUEST });
};

export const userListRequest =
  (userType, keyword, pageNum, pageSize) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: USER_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/admin/user/list?userType=${userType}&page_num=${pageNum}&page_size=${pageSize}&keyword=${keyword}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: USER_LIST_SUCCESS, payload: response.data.data });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: USER_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const userDetailsRequest = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: USER_DETAILS_REQUEST });

    axios
      .get(SERVER_URL + `/admin/user/details/${id}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: USER_DETAILS_SUCCESS, payload: response.data.data });
        } else {
          throw response.data.message;
        }
      })
      .catch((err) => {
        dispatch({
          type: USER_DETAILS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const messageListRequest =
  (keyword, pageNum, pageSize) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: MESSAGE_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/admin/message/list?page_num=${pageNum}&page_size=${pageSize}&keyword=${keyword}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: MESSAGE_LIST_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: MESSAGE_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: MESSAGE_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const messageDetailsRequest = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: MESSAGE_DETAILS_REQUEST });

    axios
      .get(SERVER_URL + `/admin/message/details/${id}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: MESSAGE_DETAILS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw response.data.message;
        }
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_DETAILS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: MESSAGE_DETAILS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const productListRequest =
  (keyword, pageNum, pageSize) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/admin/product/list?pageNum=${pageNum}&pageSize=${pageSize}&keyword=${keyword}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: PRODUCT_LIST_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: PRODUCT_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const productDetailsRequest = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    axios
      .get(SERVER_URL + `/admin/product/details/${id}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw response.data.message;
        }
      })
      .catch((err) => {
        dispatch({
          type: PRODUCT_DETAILS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const newUserRequest =
  (userType, values) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: NEW_USER_REQUEST });

      axios
        .post(
          SERVER_URL + `/admin/user/new?userType=${userType}`,
          values,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: NEW_USER_SUCCESS,
              payload: response.data.data.user_id,
            });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: NEW_USER_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: NEW_USER_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const updateUserRequest =
  (userId, values) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: EDIT_USER_REQUEST });

      axios
        .post(SERVER_URL + `/admin/user/update/${userId}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: EDIT_USER_SUCCESS });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: EDIT_USER_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: EDIT_USER_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const userListOptionsRequest =
  (userType) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: USER_OPTIONS_LIST_REQUEST });
      axios
        .get(
          SERVER_URL + `/admin/user-list-options?userType=${userType}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: USER_OPTIONS_LIST_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: USER_OPTIONS_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: USER_OPTIONS_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };
