import React, { useState } from "react";
import Select, { components } from "react-select";
import {
  MdOutlineClose,
  MdKeyboardArrowDown,
  MdOutlineSearch,
  MdOutlineCalendarToday,
} from "react-icons/md";
import {
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineEyeInvisible,
  AiOutlineEye,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import commonStyle from "../style/commonStyle.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../style/datepickerStyle.css";
import { addDays, subDays } from "date-fns";

/*
UI guideline

font size:
PC: body: 16px-18px(1rem), heading: 1.96倍大于body size, 32px/ 2rem
mobile: 14-16px,, heading: 1.3倍大于body size, 21px/ 1.3rem

margin: 8px的倍数
padding: 8px的倍数

x 方向的padding是y方向的3倍

button text 大小和body一致
*/

function FieldLabel({ label }) {
  return <p className="w-full mt-6">{label}</p>;
}

function ReadOnlyField({ label, readOnlyName }) {
  return (
    <div className="w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="w-full mt-2 h-12 bg-lightGray flex items-center px-2">
        {readOnlyName}
      </div>
    </div>
  );
}

function TextField({
  type,
  label,
  name,
  value,
  placeholder,
  onChange,
  autoFocus = false,
  readOnly,
  error,
  unit = "",
}) {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  return (
    <div className="relative w-full mt-6">
     <label className="w-full text-base text-black">{label}</label>
      <div className="relative flex items-center w-full mt-2">
        <input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          autoFocus={autoFocus}
          readOnly={readOnly}
          className={`w-full h-12 pl-4 py-2 rounded border-borderGray border-2 ${readOnly
              ? "bg-lightGray border-lightGray text-gray-500 cursor-not-allowed"
              : "border-borderGray  hover:border-black focus:outline-black focus:border-black active:outline-black" }`}
        ></input>
        {unit ? (
          <span className="absolute right-0 w-10 flex items-center justify-center">
            {unit}
          </span>
        ) : (
          <></>
        )}
      </div>
      {error ? <p className="w-full text-red-500 mt-2 ">{error}</p> : <></>}
    </div>
  );
}

function PasswordField({
  name,
  value,
  label,
  autoFocus,
  placeholder,
  onChange,
  error,
  showHints = true,
  readOnly,
}) {
  const [isFocused, setIsFocused] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [hint1, setHint1] = useState(false);
  // const [hint2, setHint2] = useState(false);
  // const [hint3, setHint3] = useState(false);
  // const [hint4, setHint4] = useState(false);
  // const [hint5, setHint5] = useState(false);

  // handle password field changes

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // const lowerCaseCount = (value.match(/[a-z]/g) || []).length;
    // const upperCaseCount = (value.match(/[A-Z]/g) || []).length;
    // const numberCount = (value.match(/\d/g) || []).length;
    // const specialCount = (value.match(/[~^@#$!%*?&]/g) || []).length;
    setHint1(value.length > 7 ? true : false);

    // setHint2(
    //   numberCount > 0 ? componentsStyle.hintPassed : componentsStyle.hintError
    // );
    // setHint3(
    //   upperCaseCount > 0
    //     ? componentsStyle.hintPassed
    //     : componentsStyle.hintError
    // );
    // setHint4(
    //   lowerCaseCount > 0
    //     ? componentsStyle.hintPassed
    //     : componentsStyle.hintError
    // );

    // setHint5(
    //   specialCount > 0 ? componentsStyle.hintPassed : componentsStyle.hintError
    // );

    onChange(value);
  };

  const handleShowPwdChange = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className="relative w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="relative flex items-center w-full mt-2">
        <input
          type="password"
          name={name}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          autoFocus={autoFocus}
          readOnly={readOnly}
          className="w-full h-12 pl-4 py-2 rounded border-borderGray border-2 focus:outline-black focus:border-black active:outline-black"
        ></input>
        <button
          className="absolute right-0 w-10 flex items-center justify-center"
          onClick={handleShowPwdChange}
        >
          {showPassword ? (
            <AiOutlineEye className="w-6 h-6 text-gray-500" />
          ) : (
            <AiOutlineEyeInvisible className="w-6 h-6 text-gray-500" />
          )}
        </button>
      </div>

      {error ? <p className="w-full text-red-500 mt-2 ">{error}</p> : <></>}
      {showHints && value.length > 0 && isFocused ? (
        <div className="absolute top-20 w-full shadow-md py-2 bg-white">
          <div
            className={`w-full flex items-center ${
              hint1 ? "text-green-500" : "text-black"
            }`}
          >
            <AiOutlineCheckCircle />
            <p style={{ marginLeft: "2px" }}>At least 8 characters</p>
          </div>
          {/* <div className={hint2}>
          <AiOutlineCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 number</p>
        </div>
        <div className={hint3}>
          <AiOutlineCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 uppercase letter</p>
        </div>
        <div className={hint4}>
          <AiOutlineCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 lowercase letter</p>
        </div>
        <div className={hint5}>
          <AiOutlineCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 special character</p>
        </div> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function TextAreaField({
  type,
  label,
  name,
  value,
  placeholder,
  onChange,
  rows,
  error,
  unit = "",
}) {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  return (
    <div className="relative w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="relative flex items-center w-full mt-2">
        <textarea
          type={type}
          name={name}
          value={value ? value : ""}
          onChange={handleChange}
          placeholder={placeholder}
          className="w-full min-h-12 pl-4 py-2 rounded border-borderGray border-2 focus:outline-black focus:border-black active:outline-black"
          rows={rows}
        ></textarea>
        {unit ? (
          <span className="absolute right-0 w-10 flex items-center justify-center">
            {unit}
          </span>
        ) : (
          <></>
        )}
      </div>
      {error ? <p className="w-full text-red-500 mt-2 ">{error}</p> : <></>}
    </div>
  );
}

const RatioField = ({ name, label, isOn, onChange }) => {
  return (
    <div className="w-full mt-6">
      <label
        className={`${commonStyle.checkboxContainer} ${commonStyle.labelSize}`}
      >
        {label}
        <input
          type="checkbox"
          name={name}
          checked={isOn}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className={commonStyle.checkmark}></span>
      </label>
    </div>
  );
};

function SelectField({
  value,
  label,
  selectOptions,
  onChange,
  error,
  extractKey,
  placeHolder,
  clearable = true,
  readOnly = false,
  readOnlyName = "",
}) {
  const handleSelect = (value) => {
    onChange(extractKey && value ? value[extractKey] : value);
  };

  // set select dropdown icon
  const DropdownIndicator = (DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...DropdownIndicatorProps}>
        <MdKeyboardArrowDown />
      </components.DropdownIndicator>
    );
  };
  // set select clear icon
  const ClearIndicator = (ClearIndicatorProps) => {
    return (
      <components.DropdownIndicator {...ClearIndicatorProps}>
        <MdOutlineClose />
      </components.DropdownIndicator>
    );
  };

  // set custom color
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "black" : "#E5E7E7",
      borderWidth: "2px",
      outline: state.isFocused ? "black" : "none",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        borderColor: state.isFocused ? "black" : "black",
      },
      innerHeight: "48px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      padding: "0 6px",
    }),
    option: (base, state) => ({
      ...base,
      color: "black",
      backgroundColor: state.isSelected
        ? "var(--light-gray-bc)"
        : "var(--white-color)",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "var(--light-gray-bc)",
      },
    }),

    IndicatorsContainer: (base, state) => ({
      ...base,
      backgroundColor: "var(--main-color)",
    }),
  };

  return (
    <div className="w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      {readOnly ? (
        <div className="w-full mt-2 h-12 bg-lightGray flex items-center px-2">
          {readOnlyName}
        </div>
      ) : (
        <div className="w-full mt-2">
          <Select
            value={
              extractKey
                ? selectOptions.filter((e) => e[extractKey] === value)
                : value
            }
            components={{ DropdownIndicator, ClearIndicator }}
            placeholder={placeHolder}
            onChange={(e) => handleSelect(e)}
            isClearable={clearable}
            options={selectOptions}
            styles={customStyles}
          />
        </div>
      )}

      <p className="w-full text-red-500 mt-2 ">{error}</p>
    </div>
  );
}

function MultiSelectField({
  value,
  label,
  selectOptions,
  onChange,
  error,
  placeHolder,
  clearable = true,
}) {
  const handleSelect = (value) => {
    onChange(value);
  };

  // set select dropdown icon
  const DropdownIndicator = (DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...DropdownIndicatorProps}>
        <MdKeyboardArrowDown />
      </components.DropdownIndicator>
    );
  };
  // set select clear icon
  const ClearIndicator = (ClearIndicatorProps) => {
    return (
      <components.DropdownIndicator {...ClearIndicatorProps}>
        <MdOutlineClose />
      </components.DropdownIndicator>
    );
  };

  const MultiValueRemove = (MultiValueRemoveProps) => {
    return (
      <components.MultiValueRemove {...MultiValueRemoveProps}>
        <MdOutlineClose />
      </components.MultiValueRemove>
    );
  };
  // set custom color
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "black" : "#E5E7E7",
      borderWidth: "2px",
      outline: state.isFocused ? "black" : "none",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        borderColor: state.isFocused ? "black" : "black",
      },
      innerHeight: "48px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      padding: "0 6px",
    }),
    option: (base, state) => ({
      ...base,
      color: "black",
      backgroundColor: state.isSelected
        ? "var(--main-color)"
        : "var(--white-color)",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "var(--table-hover)",
      },
    }),

    IndicatorsContainer: (base, state) => ({
      ...base,
      backgroundColor: "var(--main-color)",
    }),
  };

  return (
    <div className="w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="w-full mt-2">
        <Select
          value={value}
          components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
          placeholder={placeHolder}
          onChange={(e) => handleSelect(e)}
          isClearable={clearable}
          isMulti
          options={selectOptions}
          styles={customStyles}
        />
      </div>
      <p className="w-full text-red-500 mt-2 ">{error}</p>
    </div>
  );
}

function SearchField({ initialQuery, placeholder, onChange }) {
  const [query, setQuery] = useState(initialQuery);

  const handleChange = (value) => {
    setQuery(value);
    onChange(value);
  };

  return (
    <div className="relative w-full">
      <input
        type="text"
        className="w-full h-12 px-4 py-2 border border-borderGray rounded text-black text-base focus:outline-black focus:border-black active:outline-black"
        value={query}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        style={{ height: "48px" }}
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <MdOutlineSearch className="h-5 w-5 text-gray" />
      </div>
    </div>
  );
}

function DateInput({
  label,
  error,
  disabledPreDate,
  minDay,
  value,
  onChange,
  maxDay,
}) {
  let customMaxdate = new Date();
  let customMindate = new Date();

  if (maxDay > 0) {
    customMaxdate = addDays(new Date(), maxDay);
  }

  if (minDay > 0) {
    customMindate = subDays(new Date(), minDay);
  }

  return (
    <div className="w-full mt-4 pr-2">
      <label className="w-full text-base text-black">{label}</label>

      <div className="w-full rounded border border-solid border-borderGray mt-2 pl-2">
        <DatePicker
          wrapperClassName="datepicker"
          prevMonthButtonDisabled={disabledPreDate}
          showPopperArrow={false}
          selected={value}
          onChange={(date) => onChange(date)}
          minDate={customMindate}
          maxDate={customMaxdate}
          placeholderText="Select a day"
          dateFormat="yyyy/MM/dd"
          disabledKeyboardNavigation
        />
      </div>
      {error && !value ? <p className="text-red-500">{error}</p> : <></>}
    </div>
  );
}

function PureTextField({
  type,
  name,
  value,
  placeholder,
  onChange,
  autoFocus = false,
  hasBorder = false,
  showSearchIcon = false,
  dollarIcon = false,
}) {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  return (
    <div className="relative flex items-center w-full text-black">
      {dollarIcon ? (
        <span className="absolute left-0 w-10 flex items-center justify-center">
          $
        </span>
      ) : (
        <></>
      )}
      <input
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        autoFocus={autoFocus}
        className={`w-full h-12 ${
          dollarIcon ? "pl-8" : "pl-4"
        } py-2 rounded border-borderGray hover:border-black focus:outline-black focus:border-black active:outline-black ${
          hasBorder ? "border-2" : ""
        } `}
      ></input>
      {showSearchIcon ? (
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <MdOutlineSearch className="h-5 w-5 text-gray" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export {
  FieldLabel,
  TextField,
  PasswordField,
  RatioField,
  SelectField,
  MultiSelectField,
  ReadOnlyField,
  TextAreaField,
  SearchField,
  DateInput,
  PureTextField,
};
