import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  collectionOtionsList
} from "../../api/webContentRequets";
import Pagination from "../../components/Pagination";
import ScreenTitle from "../../components/ScreenTitle";
import { TextLinkButton } from "../../components/buttons";
import { SelectProductCollectionDialog } from "../../components/dialogs";
import { SearchField } from "../../components/inputs";
import { ProductTypeTag } from "../../components/productComponents";
import {
  ErrorMessage,
  Loader,
  ProductName,
  ProductNameLink,
  ProductStatusTag,
} from "../../components/smallComponents";
import { productListRequest } from "../../redux/actions/userActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

export default function ProductList() {
  useDocumentTitle("Product List");
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, products, success, error } = useSelector(
    (state) => state.productList
  );

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const pageSize = 12;
  const [pageNum, setPageNum] = useState(0);
  const [keyword, setKeyword] = useState("");

  const [collectionModal, setCollectionModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedProductExistCollections, setSelectedProductExistCollections] =
    useState("");

  const [collections, setCollections] = useState([]);
  const [updateCount, setUpdateCount] = useState(1);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    dispatch(productListRequest(keyword, pageNum, pageSize));
  }, [dispatch, pageNum, keyword, updateCount]);

  useEffect(() => {
    const getCollectionOptionList = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await collectionOtionsList(config, dispatch);
        if (responseData.data.success) {
          setCollections(responseData.data.data);
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    getCollectionOptionList();
  }, [dispatch]);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  const onCollectionClick = (e, pro) => {
    e.preventDefault();
    setCollectionModal(true);
    setSelectedProduct(pro.id);
    setSelectedProductExistCollections(pro.collections);
  };

  const handleSearchChange = (value) => {
    setKeyword(value);
    setPageNum(0);
  };

  const successActionDone = () => {
    setUpdateCount(updateCount + 1)
  };

  return (
    <div className={commonStyle.pageContainer}>
      {collectionModal && (
        <SelectProductCollectionDialog
          modalOpen={collectionModal}
          modalTitle={"Select Collection"}
          modalText={"Select collection"}
          successActionText={"Assign collection to product successfully"}
          modalButtonText={"Save"}
          onClose={() => setCollectionModal(false)}
          successActionDone={successActionDone}
          collections={reqStatus.success ? collections : []}
          selectedCollections={selectedProductExistCollections}
          productId={selectedProduct}
        />
      )}

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/products">
            Products
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Product List" />
        <div className="w-1/2 mt-8">
          <SearchField
            placeholder="Type brand,model, reference number to search..."
            initialQuery={keyword}
            onChange={handleSearchChange}
          />
        </div>

        {loading ? (
          <Loader mess="Requesting products..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Product</th>
                  <th>Seller</th>
                  <th>Status</th>
                  <th>Collections</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {products.products.length > 0 ? (
                  products.products.map((item, index) => (
                    <tr key={index}>
                      <td className="w-[160px]">
                        {" "}
                        <ProductTypeTag type={item.type} />
                      </td>
                      <td>
                        <ProductNameLink
                          productId={item.id}
                          image={item.imageUris.length ? item.imageUris[0] : ""}
                          brandName={item.brandName}
                          modelName={item.modelName}
                          referenceNumber={item.referenceNumber}
                          price={item.price}
                        />
                      </td>

                      <td>
                        <div>
                          <p>
                            {item.type === "SELLERPRODUCT"
                              ? item.sellerName
                              : "WatchSpace"}
                          </p>
                          {item.type === "SELLERPRODUCT" &&
                          item.create_by_admin_id > 0 ? (
                            <p className="text-xs mt-2 w-fit text-white bg-brandColor rounded-full px-3 py-1">
                              WatchSpace Added
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td>
                        <ProductStatusTag currState={item.status} />
                      </td>
                      <td>
                        {item.collections && item.collections.length ? (
                          <div className="grid grid-cols-1 gap-1">
                            {item.collections.map((item, index) => (
                              <div
                                key={index}
                                className="w-fit text-sm bg-brandYellow rounded-full text-white px-2 py-1"
                              >
                                {item.name}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                        {reqStatus.success && (
                          <button
                            className="text-sm text-blue-500 hover:underline hover:cursor-pointer mt-2"
                            onClick={(e) => onCollectionClick(e, item)}
                          >
                            Add a New Collection
                          </button>
                        )}
                      </td>

                      <td>
                        <TextLinkButton
                          name="View Details"
                          path={`/products/details/${item.id}`}
                        />

                        {item.create_by_admin_id === userInfo.data.user_id ? (
                          <div className="w-full mt-4">
                            <TextLinkButton
                              name="Update Product"
                              path={
                                item.type === "SELLERPRODUCT"
                                  ? `/products/seller/edit/${item.id}`
                                  : item.type === "PLATFORMPRODUCT"
                                  ? `/products/platform/edit/${item.id}`
                                  : item.type === "CONSIGNEDPRODUCT"
                                  ? `/products/consignment/edit/${item.id}`
                                  : ``
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        {Number(item.consign_order_id) > 0 ? (
                          <div className="mt-4">
                            <TextLinkButton
                              name="View Consignment Quote Details"
                              path={`/consignment/details/${item.consign_order_id}`}
                            />{" "}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>There are no product available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {products.products.length > 0 ? (
              <Pagination
                totalPageNum={products.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
