import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  editUserReducer,
  messageDetailsReducer,
  messageListReducer,
  newUserReducer,
  productDetailsReducer,
  productListReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
} from "./redux/reducers/userReducers";
import {
  brandListReducer,
  brandOptionListReducer,
  deleteWatchReducer,
  editWatchReducer,
  modelListByBrandReducer,
  modelListReducer,
  newBrandReducer,
  newModelReducer,
  newWatchReducer,
  watchConstantOptionsReducer,
  watchDetailsReducer,
  watchListReducer,
} from "./redux/reducers/watchReducers";
import {
  consignmentQuoteDetailsReducer,
  consignmentQuoteListReducer,
  orderDetailsReducer,
  orderListReducer,
  serviceQuoteDetailsReducer,
  serviceQuoteListReducer,
  updateOrderPriceReducer,
  updateOrderShippingFeeReducer,
} from "./redux/reducers/orderReducers";
import { userListOptionsReducer } from "./redux/reducers/userReducers";

const reducer = combineReducers({
  //login and register reducers
  userLogin: userLoginReducer,
  watchConstantOptions: watchConstantOptionsReducer,
  newBrand: newBrandReducer,
  newModel: newModelReducer,
  brandList: brandListReducer,
  modelList: modelListReducer,
  brandOptionList: brandOptionListReducer,
  modelListByBrand: modelListByBrandReducer,
  newWatch: newWatchReducer,
  watchList: watchListReducer,
  editWatch: editWatchReducer,
  watchDetails: watchDetailsReducer,
  userList: userListReducer,
  userDetails: userDetailsReducer,
  orderList: orderListReducer,
  orderDetails: orderDetailsReducer,
  updateOrderPrice: updateOrderPriceReducer,
  updateOrderShippingFee: updateOrderShippingFeeReducer,
  consignmentList: consignmentQuoteListReducer,
  consignmentDetails: consignmentQuoteDetailsReducer,
  messageList: messageListReducer,
  messageDetails: messageDetailsReducer,
  productList: productListReducer,
  productDetails: productDetailsReducer,
  userOptionsList: userListOptionsReducer,
  newUser: newUserReducer,
  editUser: editUserReducer,
  deleteWatch: deleteWatchReducer,
  serviceList: serviceQuoteListReducer,
  serviceDetails: serviceQuoteDetailsReducer,
});

// get userinfo from local storage
const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middlewares = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
