// get package list
import axios from "axios";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;



export const orderActionRequest = async (orderType, orderId, userType, actionName, actionInfo, config) => {

  if(!orderType){
    return;
  }

  let url = ""
  if(orderType === "standardOrder"){
    url = `/admin/order/action/${orderId}?userType=${userType}&actionName=${actionName}&actionInfo=${actionInfo}`;
  }else if(orderType === "consignment") {
    url = `/admin/consignment/action/${orderId}?userType=${userType}&actionName=${actionName}&actionInfo=${actionInfo}`
  } else if(orderType === "service") {
    url = `/admin/service/action/${orderId}?userType=${userType}&actionName=${actionName}&actionInfo=${actionInfo}`
  }

  try {
    const response = await axios.get(
      SERVER_URL + url,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};



export const createNewOrderActionRequest = async (values, config) => {

  try {
    const response = await axios.post(
      SERVER_URL + `/admin/order/create`,
      values,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createNewPreOrderActionRequest = async (values, config) => {

  try {
    const response = await axios.post(
      SERVER_URL + `/admin/order/pre-order/create`,
      values,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};











