

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const UPDATE_ORDER_PRICE_REQUEST = "UPDATE_ORDER_PRICE_REQUEST";
export const UPDATE_ORDER_PRICE_SUCCESS = "UPDATE_ORDER_PRICE_SUCCESS";
export const UPDATE_ORDER_PRICE_FAIL = "UPDATE_ORDER_PRICE_FAIL";

export const UPDATE_ORDER_SHIPPING_FEE_REQUEST = "UPDATE_ORDER_SHIPPING_FEE_REQUEST";
export const UPDATE_ORDER_SHIPPING_FEE_SUCCESS = "UPDATE_ORDER_SHIPPING_FEE_SUCCESS";
export const UPDATE_ORDER_SHIPPING_FEE_FAIL = "UPDATE_ORDER_SHIPPING_FEE_FAIL";

export const CONSIGNMENT_QUOTE_LIST_REQUEST = "CONSIGNMENT_QUOTE_LIST_REQUEST";
export const CONSIGNMENT_QUOTE_LIST_SUCCESS = "CONSIGNMENT_QUOTE_LIST_SUCCESS";
export const CONSIGNMENT_QUOTE_LIST_FAIL = "CONSIGNMENT_QUOTE_LIST_FAIL";

export const CONSIGNMENT_QUOTE_DETAILS_REQUEST = "CONSIGNMENT_QUOTE_DETAILS_REQUEST";
export const CONSIGNMENT_QUOTE_DETAILS_SUCCESS = "CONSIGNMENT_QUOTE_DETAILS_SUCCESS";
export const CONSIGNMENT_QUOTE_DETAILS_FAIL = "CONSIGNMENT_QUOTE_DETAILS_FAIL";

export const SERVICE_QUOTE_LIST_REQUEST = "SERVICE_QUOTE_LIST_REQUEST";
export const SERVICE_QUOTE_LIST_SUCCESS = "SERVICE_QUOTE_LIST_SUCCESS";
export const SERVICE_QUOTE_LIST_FAIL = "SERVICE_QUOTE_LIST_FAIL";

export const SERVICE_QUOTE_DETAILS_REQUEST = "SERVICE_QUOTE_DETAILS_REQUEST";
export const SERVICE_QUOTE_DETAILS_SUCCESS = "SERVICE_QUOTE_DETAILS_SUCCESS";
export const SERVICE_QUOTE_DETAILS_FAIL = "SERVICE_QUOTE_DETAILS_FAIL";




